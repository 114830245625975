<template>
  <div style="padding: 10px">
    <div class="audit" :style="particulars.status == 1 ? 'background-color: #f5f7fa;':particulars.status == 2 ? 'background-color: #ecf5ff':particulars.status == 3 ? 'background-color:  #fef0f0':''  ">
      <div v-for="(item,index) in particulars.auditorList" :key="index">
        <div :class="item.auditResult == 2 ?'success':(item.auditResult == 3 ? 'danger':'info')" style="display:flex;height: 26px;line-height: 26px">
          <el-tooltip
              :disabled="disabled"
              :content="item.auditorTime == null ? '待审核':item.auditorTime"
              placement="top"
              effect="light">
            <div style="font-size: 12px;font-weight: bold">&nbsp;{{item.coachName}}</div>
          </el-tooltip>
          <el-icon v-if="particulars.auditorList.length > index+1" style="font-size: 24px;margin: 0 50px;color:#a5a5a5">
            <ArrowRight/>
          </el-icon>
        </div>
      </div>
    </div>
    <div style="display: flex;justify-content: space-between;">
      <div style="width: 43%;">
        <div style="font-weight: bold;font-size: 15px;margin-bottom: 10px">退费信息</div>
        <div style="margin-left: 20px;">
          <div class="padding-top">审核记录id：{{particulars.approvalProcessId}}</div>
          <div class="padding-top">退费记录id：{{particulars.approvalProcessId}}</div>
          <div class="padding-top">申请老师：{{particulars.coachName}}</div>
          <div class="padding-top">退款金额：{{particulars.money}} 元</div>
          <div class="padding-top">账户姓名：{{particulars.nameAccount}}</div>
          <div class="padding-top">开户银行：{{particulars.accountBank}}</div>
          <div class="padding-top">账户银行：{{particulars.payAccount}}</div>
          <div class="padding-top">支行地址：{{particulars.placeAccount}}</div>
          <div>
            <div class="padding-top" style="text-align: left;" v-for="items in JSON.parse(particulars.divide) " :key="items.id">
              <span>分成人：{{ items.name }}</span>
              <el-divider direction="vertical" ></el-divider>
              <span> 分成金额：{{ items.money }} 元</span>
            </div>
          </div>
          <div class="padding-top">所属校区：{{ particulars.regionName}} - {{ particulars.campusName }}</div>
          <div style="display: flex">
            <div style="width: 240px" class="padding-top">创建日期：{{ particulars.createTime}}</div>
            <div class="padding-top">完成日期：{{ particulars.endTime}}</div>
          </div>

          <div class="padding-top">退款说明：{{particulars.remark}}</div>
          <div style="">退款沟通图片：

            <el-image class="code"
                     v-for="(item,index) in particulars.picture"
                      :key="index"
                      hide-on-click-modal
                      :src="item"
                      :preview-src-list="particulars.picture"
            >
              <template #error>
                <div class="image-slot">
                  <i class="el-icon-picture-outline"></i>
                </div>
              </template>
            </el-image>
          </div>
        </div>
      </div>
<!--  课程信息-->
      <div v-if="particulars.order!=null" style="width: 52%;">
        <div style="font-weight: bold;font-size: 15px;margin-bottom: 20px">课程信息</div>
          <div style="margin-left: 20px;">
            <div style="display: flex;justify-content: start">
              <div class="main">
                <view class="type typecolor" style="background-color: #CDA571;color: white">{{particulars.order.state}}</view>
                <div class="mainCardName">{{ particulars.order.cardAttributeRule.name}}</div>
                <div style="font-size: 12px;padding:0px 26px;color: #5A4018;margin-top: 2px;">
                  <div>学员姓名:{{particulars.order.studentName}}</div>
                  <div style="padding: 2px 0">
                    开始时间:{{particulars.order.startTime == null ? "待使用": particulars.order.startTime}}
                  </div>
                  <div>
                    结束时间:{{particulars.order.endTime == null ? "待使用": particulars.order.endTime}}
                  </div>

                  <div style="display: flex;justify-content: space-between;margin-top: 10px;width: 90%;">
                    <div>
                      <div>{{ particulars.order.remaining }}</div>
                      <div>剩余课时</div>
                    </div>
                    <div>
                      <div>{{ particulars.order.ouse }}</div>
                      <div>已用课时</div>
                    </div>
                    <div>
                      <div>{{ particulars.order.courseNum }}</div>
                      <div>课时记录</div>
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="particulars.order.sendorder!=null" class="vice">
                <view class="type typecolor" style="background-color: #58a3e9;color: white">{{particulars.order.sendorder.state}}</view>
                <div class="viceCardName">{{ particulars.order.sendorder.cardAttributeRule.name }}</div>
                <div style="font-size: 12px;padding:0px 26px;color: #456087;margin-top: 2px;">
                  <div style="padding: 4px 0">
                    开始时间:{{particulars.order.sendorder.startTime == null ? "待使用": particulars.order.sendorder.startTime}}
                  </div>
                  <div>
                    结束时间:{{particulars.order.sendorder.endTime == null ? "待使用": particulars.order.sendorder.endTime}}
                  </div>
                  <div style="display: flex;justify-content: space-between;margin-top: 10px;width: 80%;">
                    <div>
                      <div>{{ particulars.order.sendorder.remaining }}</div>
                      <div>剩余课时</div>
                    </div>
                    <div>
                      <div>{{ particulars.order.sendorder.suse }}</div>
                      <div>已用课时</div>
                    </div>
                    <div>
                      <div>{{ particulars.order.sendCourseNum }}</div>
                      <div>课时记录</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        <div style="font-weight: bold;font-size: 15px;margin: 20px 0">缴费信息</div>
        <div style="margin-left: 20px;">
          <div>收款老师：{{particulars.coachName}}</div>
          <div class="padding-top" style="display:flex;">
            <div style="width: 170px">总购课时：{{particulars.order.orderInfo.buyNumber}}</div>
            <div v-if="particulars.order.sendorder!=null" >总赠课时：{{particulars.order.orderInfo.sendNumber}} </div>
          </div>
          <div class="padding-top" style="display:flex;">
            <div style="width: 170px">卡片金额：{{particulars.order.money}} 元</div>
            <div v-if="particulars.order.reserveMoney!=null">缴费金额：
              {{particulars.order.orderInfo.tradingRecord.money + particulars.order.reserveMoney}}元 =
              <el-tooltip
                  :disabled="disabled"
                  :content="'课程金额：'+particulars.order.orderInfo?.tradingRecord?.money + '元'"
                  placement="top"
                  effect="light"
              >
                <span>  {{particulars.order.orderInfo?.tradingRecord?.money}}元 +</span>
              </el-tooltip>

              <el-tooltip
                  :disabled="disabled"
                  :content="'定金金额：'+particulars.order.reserveMoney + '元'"
                  placement="top"
                  effect="light"
              >
                <span>{{ particulars.order.reserveMoney }}元</span>
              </el-tooltip>

              </div>
            <div v-else>缴费金额：{{particulars.order.orderInfo?.tradingRecord?.money}} 元</div>
          </div>
          <div class="padding-top">商户单号：{{particulars.order.orderInfo?.tradingRecord?.transaction}}</div>
          <div class="padding-top" style="display: flex">
            <div style="width: 260px">收款日期：{{particulars.order.orderInfo.closedTime}}</div>
            <div>创建日期：{{particulars.order.createTime}}</div>
          </div>
          <div class="padding-top" style="display: flex">
            <div style="width: 260px">所属校区：{{particulars.order.campus.name}}</div>
            <div >上课校区：{{particulars.order.submitCampus.name}}</div>
          </div>
          <div class="padding-top" v-if="particulars.order.orderInfo.sendGoods">赠送器材：
            <span v-for="(item,index) in JSON.parse(particulars.order.orderInfo.sendGoods)" :key="index">
                {{item}} <el-divider direction="vertical" ></el-divider>
              </span>
          </div>
          <div>缴费沟通图片：
            <el-image v-if="particulars.order.orderInfo.credentialsImg!=null" class="code"
              hide-on-click-modal
              :src="JSON.parse(particulars.order.orderInfo.credentialsImg)[0]"
              :preview-src-list="JSON.parse(particulars.order.orderInfo.credentialsImg)"
            >
              <template #error>
                <div class="image-slot">
                  <i class="el-icon-picture-outline"></i>
                </div>
              </template>
            </el-image>
          </div>
        </div>
        </div>
      <!--  器材信息-->
      <div v-if="particulars.orders!=null" style="width: 52%;">
        <div style="font-weight: bold;font-size: 15px;">器材信息</div>
        <div style="margin-left: 20px;">
          <div class="padding-top">id：{{particulars.orders.id}}</div>
          <div class="padding-top" style="display:flex;">
            <div style="width: 160px">学员姓名：{{particulars.orders.studentName}}</div>
            <div>学员电话：{{particulars.orders.studentPhone}}</div>
          </div>
          <div class="padding-top" style="display:flex;">
            <div style="width: 160px">收款老师：{{particulars.orders.coachName}}</div>
            <div style="width: 180px">收款金额：{{particulars.orders.totalMoney}} 元</div>
            <div>收款时间：{{particulars.orders.payDate}}</div>
          </div>
          <div class="padding-top" style="display:flex;">
            <div style="width: 160px">是否业绩：
              <el-switch :value="particulars.orders.isPayment" disabled  :active-value="1" :inactive-value="0" />
            </div>
            <div style="width: 180px">商品用途：{{particulars.orders.productUse}}</div>
            <div>所属校区：{{particulars.orders.campusName}}</div>
          </div>
          <div class="padding-top">备注说明：{{particulars.orders.remark}}</div>

          <div class="padding-top" style="display:flex;">
            <div style="width: 160px">器材出库状态：{{particulars.orders.state}}</div>
          </div>
          <div class="padding-top" style="display:flex;" v-if="particulars.orders.checkCoach">
            <div style="width: 160px">器材出库审核：{{particulars.orders.checkCoach.coachname}}</div>
          </div>
          <div class="padding-top" style="display:flex;">
            <div style="margin-right: 20px">创建时间：{{particulars.orders.createTime}}</div>
            <div style="position: relative">沟通图片：
              <el-image v-if="particulars.orders.imgUrl!=''" class="code" style="position: absolute;top: -14px"
                        hide-on-click-modal
                        :src="JSON.parse(particulars.orders.imgUrl)[0]"
                        :preview-src-list="JSON.parse(particulars.orders.imgUrl)"
              >
                <template #error>
                  <div class="image-slot">
                    <i class="el-icon-picture-outline"></i>
                  </div>
                </template>
              </el-image>
            </div>
          </div>
          <div style="padding: 20px">
            <el-table
                height="206px"
                style="border-radius: 10px"
                :data="particulars.orders.child"
                :row-style="{height:20+'px'}"
                :cell-style="{color:'#909399',fontSize:'12px',padding:'6px 0px'}"
            >
              <el-table-column prop="equipmentName" label="器材名称"/>
              <el-table-column prop="num" label="数量"/>
              <el-table-column prop="money" label="单价"/>
              <el-table-column prop="money" label="总价">
                <template v-slot="{row}">
                  <span>{{ row.num * row.money }} 元</span>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
      </div>
<!--  定金信息-->
      <div v-if="particulars.earnestorder!=null" style="width: 52%;">
        <div style="font-weight: bold;font-size: 15px;margin-bottom: 10px">定金信息</div>
        <div style="margin-left: 20px;">
          <div class="padding-top">定金id：{{particulars.earnestorder.id}}</div>
          <div class="padding-top">学员姓名：{{particulars.earnestorder.name}}</div>
          <div class="padding-top">学员电话：{{particulars.earnestorder.phone}}</div>
          <div class="padding-top">商户单号：{{particulars.earnestorder.tradingRecord.transaction}}</div>
          <div style="display: flex">
            <div style="width: 240px" class="padding-top">收款金额：{{particulars.earnestorder.tradingRecord.money}} 元</div>
            <div class="padding-top">收款老师：{{particulars.earnestorder.coachName}}</div>
          </div>
          <div style="display: flex">
            <div style="width: 240px"  class="padding-top">收款时间：{{particulars.earnestorder.tradingRecord.collectionTime}}</div>
            <div class="padding-top">创建时间：{{particulars.earnestorder.createTime}}</div>
          </div>
          <div class="padding-top">备注说明：{{particulars.earnestorder.note}}</div>
          <div class="padding-top" style="position: relative">沟通图片：
            <el-image v-if="particulars.earnestorder.tradingRecord.paymentImg!=''" class="code" style="position: absolute;"
                      hide-on-click-modal
                      :src="JSON.parse(particulars.earnestorder.tradingRecord.paymentImg)[0]"
                      :preview-src-list="JSON.parse(particulars.earnestorder.tradingRecord.paymentImg)"
            >
              <template #error>
                <div class="image-slot">
                  <i class="el-icon-picture-outline"></i>
                </div>
              </template>
            </el-image>
          </div>
        </div>

<!--        <div style="margin: 40px 80px" class="card">-->
<!--          <h3 style="margin-bottom: 15px">用户信息</h3>-->
<!--          <div class="student">-->
<!--                <span>学生姓名：-->
<!--                    <span class="redItem">{{ row.earnestorder.name }}</span>-->
<!--                </span>-->
<!--            <span>学生电话：<span class="redItem">{{ row.earnestorder.phone }}</span></span>-->
<!--          </div>-->
<!--          <div class="time">-->
<!--            <div>-->
<!--              <h4>提报日期：<span class="redItem">{{ row.earnestorder.createTime }}</span></h4>-->
<!--              <h4>备注：<span class="redItem">{{ row.earnestorder.note }}</span></h4>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
      </div>
      <!-- 招商信息 -->
      <div v-if="particulars.crmOrders" style="width: 52%;">
        <div style="font-weight: bold;font-size: 15px;margin: 20px 0">缴费信息</div>
        <div style="margin-left: 20px;">
          <div>收款老师：{{particulars.crmOrders.signCoachName}}</div>
          <div class="padding-top" style="display:flex;">
            <div style="width: 170px">客户姓名：{{particulars.crmOrders.name}}</div>
            <div>客户联系方式：{{particulars.crmOrders.phone}} </div>
          </div>
          <div class="padding-top">客户地址：{{particulars.crmOrders.province+ '-' + particulars.crmOrders.city}} </div>
          <div class="padding-top" style="display:flex;">
            <div style="width: 170px">客户公司名称：{{particulars.crmOrders.companyName}}</div>
            <div>合作方式：{{particulars.crmOrders.signTypeName}}</div>
           
          </div>
          <div class="padding-top" style="display:flex;"> 
            <div  style="width: 170px">签约金额：{{particulars.crmOrders.money}} 元</div> 
            <div >实收金额：{{particulars.crmOrders.practicalMoney}}</div>
          </div>
        
          <div class="padding-top" style="display: flex">
            <div style="width: 260px">成单日期：{{particulars.crmOrders.signOrderTime}}</div>
            <div>创建日期：{{particulars.crmOrders.createTime}}</div>
          </div>
          <!-- <div class="padding-top">赠送器材：
            <span v-for="(item,index) in particulars.crmOrders.crmOrdersMoneyType" :key="index">
                {{item.dictName}} <el-divider direction="vertical" ></el-divider>
              </span>
          </div> -->
          <div>缴费沟通图片：
            <el-image v-if="particulars.crmOrders.contractImg!=null" class="code"
              hide-on-click-modal
              :src="particulars.crmOrders.contractImg"
              :preview-src-list="[particulars.crmOrders.contractImg]"
            >
              <template #error>
                <div class="image-slot">
                  <i class="el-icon-picture-outline"></i>
                </div>
              </template>
            </el-image>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ReturnDetail",
  props: {
    particulars: String,
  },
}
</script>

<style scoped>
.audit{
  display:flex;
  padding: 8px 30px;
  margin-top: -20px;
  border-radius: 10px;
  margin-bottom: 20px;
}
.danger{
  color: #F56C6C;
}
.success{
  color: #409EFF;
}
.info{
  color: #909399;
}
.padding-top{
  padding-top: 10px;
}
.code {
  margin-top: 6px;
  width: 40px;
  height: 40px;
  border-radius: 8px
}
.card {
  display: flex;
  justify-content: space-around;
  border-bottom: solid 1px #e4e7ed;
  margin-bottom: 8px;
}
.mainCardName{
  font-size: 16px;
  font-weight: bold;
  background: linear-gradient(to right, #bb9c6e, #000000);
  font-style: italic;-webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  padding: 10px 16px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.viceCardName{
  font-size: 16px;
  font-weight: bold;
  background: linear-gradient(to right, #81B7EB, #000000);
  font-style: italic;-webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  padding: 10px 16px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.type{
  line-height: 26px;
  font-style: italic;
  border-radius: 0px 15px 0px 15px;
  font-weight: bold;
  height: 26px;
  width: 66px;
  font-size: 12px;
  text-align: center;
  float:right;
}
.main{
  background: -webkit-linear-gradient(left,#F5DCB5,#e3c28f);
  height: 152px;
  border-radius: 15px;
  width: 266px
}
.vice{
  background: -webkit-linear-gradient(left,#C9E3F8,#81B7EB);
  height: 140px;
  border-radius: 15px;
  margin-left: 30px;
  width: 256px
}

</style>